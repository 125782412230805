$(window).scroll(function(e) {
  let $el = $(".blogposts--item__share");
  let isPositionFixed = $el.css("position") == "fixed";
  var scrollBottom =
    $(document).height() - $(window).height() - $(window).scrollTop();

  if ($(this).scrollTop() > 200 && !isPositionFixed) {
    $el.css({ position: "fixed", top: "250px" });
  } else if (scrollBottom < 650) {
    $el.css({ display: "none" });
  } else {
    $el.css({ display: "" });
  }
  if ($(this).scrollTop() < 200 && isPositionFixed) {
    $el.css({ position: "static", top: "1000px" });
  }
});
